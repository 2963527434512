<template>
  <div class="enterprise">
    <div class="title but">{{list.merchantName}}</div>
    <div style="padding: 0 20px;">
      <ul>
        <li><strong>检验地点</strong> <p>{{`${list.address}`}}</p></li>
        <li><strong>检验日期</strong><p>{{list.inspection}}</p></li>
        <li><strong>船名</strong><p>{{list.shipName}}</p></li>
        <li><strong>装货港&开船日期</strong><p>{{list.begin}}</p></li>
        <li><strong>到货港&预计日期</strong><p>{{list.end}}</p></li>
        <li><strong>集装箱号</strong><p>{{list.c_name}}</p></li>
        <li><strong>集装箱类型</strong><p>{{list.weight}}</p></li>
        <li><strong>集装箱毛重</strong><p>{{list.type}}</p></li>
        <li><strong>集装箱内其他混装非危险货物</strong><p>{{list.other}}</p></li>
      </ul>
    </div>

    <!-- <div class="but">资质信息</div>
    <p style="padding: 20px;">{{list.honor}}</p> -->
    <!-- <div v-for="(itme,index) in list.merchantInfo" :key="index">
      <div style="margin-left: 20px;">{{itme.title}}</div>
      <van-image width="100%" :src="itme.picUrl" style="margin-top: 10px"></van-image>
    </div> -->
    
  </div>
</template>
<script>
export default {
  props:{
    list:{
      type: Object,
      required: true
    }
  },
  data(){
    return{
    }
  },
  created(){
  },
  methods:{

  }
}
</script>
<style scoped>
.title{
    text-align: center;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
}
.enterprise .but{
    margin-top: 20px;
    color: white;
    line-height: 3rem;
    padding-left: 20px;
    font-size: 1rem;
    height: 3rem;
    background-color: #005BAC;
}
ul{
  list-style: none;
}
p{
  line-height: 25px;
}
li{
  line-height: 30px;
}
</style>

