<template>
  <div class="information">
    <div class="but">检查内容</div>
    <van-collapse v-model="activeNames" accordion>
      <van-collapse-item v-for="(item,index) in list.traceList" :key="index" :name="item.index">
        <template #title>
          <span>{{`${item.index}、${item.title}`}} </span>
        </template>
        <p><strong>{{item.content}}</strong> </p>
        <van-radio-group style="margin-top:10px" v-model="item.isPass" disabled>
          <van-radio  :name="true">通过</van-radio>
          <van-radio style="margin-top:5px" :name="false">不通过</van-radio>
        </van-radio-group>
        <div v-if="item.fileType == '1'">
          <div v-for="img in item.fileURL" :key="img" style="padding: 10px;">
            <el-image style="width:200px" :src="img" class="topImg" :preview-src-list="item.fileURL"></el-image>
          </div>
        </div>
        <div v-if="item.fileType=='2'">
          <div v-for="img in item.fileURL" :key="img" style="padding: 10px;">
            <el-image style="width:200px" :src="img" class="topImg" :preview-src-list="item.fileURL"></el-image>
          </div>
          <div v-for="(data,index) in item.pdfURL" :key="index" @click="imgClick(data)" style="padding: 10px;">
            <p style="color:deepblue">点击预览PDF</p>
          </div>
        </div>
        <div v-if="item.fileType =='3'">
          <div v-for="(data,index) in item.fileURL" :key="index" @click="imgClick(data)" style="padding: 10px;">
            <p style="color:deepblue">点击预览PDF</p>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div class="but">其他信息</div>
      <van-collapse v-if="list.otherList && list.otherList.length>0" v-model="otherActiveNames" accordion>
        <van-collapse-item v-for="(it,index) in list.otherList" :key="index">
          <template #title>
            <span>{{`${it.index}、${it.title}`}} </span>
          </template>
          <span><strong>{{it.content}} </strong> </span>
        </van-collapse-item>
      </van-collapse>
    <div class="bottomBut">
      <van-button type="info" round style="width: 80%;" @click="$router.push({name: 'certificate',params:{data:{isFake:true}}})">区块链证书</van-button>
    </div>
  </div>
</template>
<script>
import Video from "@/components/video.vue";
export default {
  components: { Video },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeNames: [],
      otherActiveNames:[]
    };
  },
  created() {
    console.log(this.list);
  },
  methods: {
    // PDF
    imgClick(e) {
      console.log(e);
      location.href = e;
      window.location.href=e
    },
  },
};
</script>
<style scoped>
.contentTop {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}
.topIcon {
  background: #005bac;
  color: #fff;
  font-size: 21px;
  padding: 5px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.but{
    margin-top: 20px;
    color: white;
    line-height: 3rem;
    padding-left: 20px;
    font-size: 1rem;
    height: 3rem;
    background-color: #005BAC;
}
.topImg {
  padding: 10px;
}
.textContent {
  padding: 10px;
  color: #000;
}
.textContent_span {
  color: #666;
}
.bottomBut{
  text-align: center;
  margin: 20px 0;
}
</style>

