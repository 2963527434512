<template>
  <div class="product" v-loading="loading">
    <div v-show="contentshow">
      <div><img src="../../assets/top1.png" alt="" style="width: 100%" /></div>
      <!-- <div><van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(image, index) in data.picUrls" :key="index">
              <img  :src="image" alt="" style="width: 100%"/>
            </van-swipe-item>
          </van-swipe>
        </div> -->
      <!-- <div class="baccol-div">
        <div class="baccol-span"><span class="prodName">产品名称</span> <span class="prodNameText">{{data.proName}}</span> </div>
        <div class="baccol-span"><span class="n-enterprise-name">企业名称</span><span class="n-name-content">{{data.merchantName}}</span></div>
      </div> -->
      <van-tabs v-model="activeName" swipeable color="#0475d7">
        <!-- <van-tab title="产品信息"><Product :list="data"/></van-tab> -->
        <van-tab title="企业信息"><Enterprise :list="data" /></van-tab>
        <van-tab title="装运前检验内容"><Information :list="data" /></van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
import Product from "@/components/product.vue";
import Enterprise from "./enterprise.vue";
import Information from "./information.vue";
export default {
  data() {
    return {
      activeName: "1",
      loading: false,
      data: {
        merchantName: "白石钙株式会社",
        address: `株式会社筑港横滨化学品中心仓库2号;
         日本神奈川县横滨市鹤见区9-15,
         230-0053号
         `,
        inspection: "2021年 7月 13日",
        shipName: "GLORY SHENGDONG Voy.No2128W",
        begin: "横滨,2021年 7月 16日",
        end: "上海,约 2021年 7月 18日",
        c_name: "TCNU3364465",
        weight: "11,169.50公斤 (自重:3,700.00公斤)",
        type: "40英尺,干货柜",
        other: "无",
        // A 固定
        traceList:[
          {
            index:1,
            title:'包装撒漏/泄露',
            content:'检查内/外包装容器之间是否撒漏泄漏',
            isPass:true,
            fileType:'1',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/1.png']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/1.JPG']
          },
          {
            index:2,
            title:'包装特殊要求',
            content:'检查是否符合危险货物包装的特殊要求（包括衬垫或吸附材料）',
            isPass:true,
            fileType:'1',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/2.jpg']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/2.JPG']
          },
          {
            index:3,
            title:'包装完好',
            content:'检查包装外观是否完好，是否存在损毁/残损/撒漏/渗',
            isPass:true,
            fileType:'1',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/3.jpg']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/3.JPG']
          },
          {
            index:4,
            title:'包装包式',
            isPass:true,
            content:'检查货物包装型式是否符合要求',
            fileType:'1',
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/4.JPG']
          },
          {
            index:5,
            title:'包装规格',
            content:'检查包装规格是否符合要求',
            isPass:true,
            fileType:'1',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/3.jpg']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/5.JPG']
          },
          {
            index:6,
            title:'包装单件重量',
            content:'检查包装单件重量（包括填充量）是否符合要求',
            isPass:true,
            fileType:'1',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/3.jpg']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/6.JPG']
          },
          {
            index:7,
            title:'包装标记',
            content:'检查包装标记是否符合要求',
            isPass:true,
            fileType:'1',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/3.jpg']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/7.JPG']
          },
          {
            index:8,
            title:'安全数据单',
            content:'检查货物随附的安全数据单信息内容是否符合要求',
            isPass:true,
            fileType:'3',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/EA%20110-MSDS-%E4%B8%AD%E6%96%87.pdf']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/8.pdf']
          },
          {
            index:9,
            title:'包装标签',
            content:'检查货物包装醒目位置是否加贴栓挂或喷印危险化学品公示标签',
            isPass:true,
            fileType:'1',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/5.png']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/9.JPG']
          },
          {
            index:10,
            title:'中文标签',
            content:'检查货物危险化学品危险公示标签是否为中文，并且标签载明的内容是否符合要求',
            isPass:true,
            fileType:'2',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/5.png']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/10.JPG'],
            pdfURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/10-1.pdf']
          },
          {
            index:11,
            title:'运输警示标签',
            content:'检查货物运输警示标签是否符合要求',
            isPass:true,
            fileType:'3',
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/10-1.pdf']
          },
          {
            index:12,
            title:'数量',
            content:'检查数量是否与申报一致',
            isPass:true,
            fileType:'1',
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/2.jpg']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/12.png'],
          },
          {
            index:13,
            title:'重量',
            content:'检查重量是否与申报一致',
            isPass:true,
            fileType:'1',
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/13.png'],
            // fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/7.jpg','https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/6.png']
          },
          {
            index:14,
            title:'集装箱情况',
            content:'集装箱清洁及装货情况',
            isPass:true,
            fileType:'1',
            // fileURL:['']
            fileURL:['https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/14-1.JPG',
            'https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/14-2.JPG',
            'https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/14-3.JPG',
            'https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/14-4.JPG'],
          }
        ],
        // B 通用
        otherList:[
          {
            index:1,
            title:'集装箱号',
            content:'TCNU3364465'
          },
          {
            index:2,
            title:'申报品名',
            content:`黏合剂，含易燃液体`
          },
          {
            index:3,
            title:'装运港',
            content:'横滨'
          },
          {
            index:4,
            title:'检验日期',
            content:'2021年7月13日'
          },
          {
            index:5,
            title:'UN编号',
            content:'UN1133'
          },
          {
            index:6,
            title:'报关单号',
            content:'220120211000313517'
          },
        ]
      },
      contentshow: true,
    };
  },
  components: { Product, Enterprise, Information },
  created() {
    this.batchId = this.$route.params.batchId;
    // this.init()
  },
  methods: {
    init() {
      this.$axios
        .get("/show/index?batchId=" + localStorage.getItem("batchId"))
        .then((res) => {
          console.log(res);
          if (res.data.retCode == "9999") {
            this.contentshow = false;
            Notify({ type: "warning", message: res.data.retMsg });
          } else {
            this.data = res.data.retData;
          }
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.body {
  background: #f8f8f8;
  height: 100vh;
}
.prodName {
  padding: 3px;
  border: 1px solid #ea6200;
  border-radius: 0.2rem;
  text-align: center;
  display: inline-block;
  height: 1rem;
  line-height: 1rem;
  width: 3rem;
  color: #ea6200;
  font-size: 0.65rem;
}
.prodNameText {
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #000;
  font-weight: 600;
  padding-left: 0.12rem;
  word-wrap: break-word;
}
.n-enterprise-name {
  padding: 3px;
  border-radius: 0.2rem;
  text-align: center;
  display: inline-block;
  height: 1rem;
  line-height: 1rem;
  width: 3rem;
  font-size: 0.65rem;
  border: 1px solid #484848;
  color: #484848;
}
.n-name-content {
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #000;
  font-weight: 600;
  padding-left: 0.3rem;
  word-wrap: break-word;
}
.baccol-div {
  padding: 10px 20px;
  height: auto;
  margin: auto;
  font-family: "microsoft yahei";
  /* padding: 0 0.9rem; */
  background-color: #e0e3e6;
}
.baccol-span {
  padding: 0.4rem 0 0.05rem;
}
.product >>> .van-tab--active {
  color: #0475d7;
}
.product >>> .van-tab {
  color: #000;
  font-size: 0.9rem;
}
</style>

